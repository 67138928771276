
.navbar-light {
    min-height: 90px;
    background-color: rgba(255, 255, 255, 0.95);

    .nav-link {
        font-size: 1.4em;
    }
}

.navbar-shadow {
    border: 0px solid;
    box-shadow: 0px 0px 7px $alchemist-gray-400;
}
