
// // KeyFrames animation
// @keyframes fade-in {
//     0% {
//         opacity: 0;
//     }

//     100% {
//         opacity: 1;
//     }
// }

@include keyframes(fade-in) {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

}
