// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

//  Usage 
// .container-with-floated-children {
//   @extend %clearfix;
// }
%clearfix {
    *zoom: 1;

    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}


// Usage
// .visually-hidden {
//   @extend %visuallyhidden;
// }
%visuallyhidden {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
}


%responsiveImage {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-self: center;
    align-items: center;
}
