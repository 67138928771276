// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------
@mixin respond-to($breakpoint) {
    $raw-query: map-get($breakpoints, $breakpoint);

    @if $raw-query {
        $query: if(type-of($raw-query)=='string',
                unquote($raw-query),
                inspect($raw-query));

        @media #{$query} {
            @content;
        }
    }

    @else {
        @error 'No value found for `#{$breakpoint}`. '
        +'Please make sure it is defined in `$breakpoints` map.';
    }
}


@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

// usage  @include font-size(14px)
@mixin font-sizes($size) {
    font-size: $size;
    font-size: calculateRem($size);
}


//  USAGE
// @include keyframes(slide-down) {
//     0% { opacity: 1; }
//     90% { opacity: 0; }
//   }

//   .element {
//     width: 100px;
//     height: 100px;
//     background: black;
//     @include animation('slide-down 5s 3');
//   }

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @-ms-keyframes #{$animation-name} {
        @content;
    }

    @-o-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    animation: #{$str};
}



// usage
//   @include transition(color .3s ease);
@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// usage @include no-select
@mixin no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

// usage  @include opacity(0.8);
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}
