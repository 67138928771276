.download-app-store {
    @extend %responsiveImage;

    .img-btn {
        flex-shrink: 2;
        flex-basis: auto;

        img {
            flex-shrink: 2;
            max-height: 60px;
        }
    }
}
