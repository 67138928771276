// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------


$alchemist-victoria-1: #505db7;
$alchemist-victoria-2: #37367a;
$alchemist-sky-1: #8499ff;
$alchemist-sky-2: #5b61d9;

$alchemist-gold-1: #dec274;
$alchemist-gold-2: #ffedb9;
$alchemist-gold-3: #d3b671;
$alchemist-gold-4: #ffb304;


// Whites
$alchemist-white-100: #fff;
$alchemist-white-200: #fafafa;
$alchemist-white-300: #f7f7f7;

// GRAY - FILL

$alchemist-gray-100: #f3f8ff;
$alchemist-gray-200: #e7eefc;
$alchemist-gray-300: #d8e0f5;
$alchemist-gray-400: #c4cee6;
$alchemist-gray-500: #aeb7d0;
$alchemist-gray-600: #8d97ba;
$alchemist-gray-600: #8d97ba;
$alchemist-gray-700: #5f657e;
$alchemist-gray-800: #414660;
$alchemist-gray-900: #2d2f3a;
$alchemist-gray-1000: #1f2127;


// COLOR BLENDER

$alchemist-sea-sky-1: #a8b5f8;
$alchemist-sea-sky-2: #5e76d6;
$alchemist-sea-sky-3: #505db7;
$alchemist-sea-sky-4: #434799;
$alchemist-sea-sky-5: #013b57;
$alchemist-sea-sky-6: #29245e;


// STATUS

$alchemist-status-success: #5ee5b2;
$alchemist-status-error: #e55e5e;
$alchemist-status-warning: #da3d3d;

$alchemist-status-info: #e5fcf8;
$alchemist-status-info-stroke: #00e8c1;

$alchemist-status-message-1: #ebf1ff;
$alchemist-status-message-2: #7797f5;
$alchemist-status-message-stroke: #ebf1ff;

$alchemist-status-success: #bde7e6;
$alchemist-status-blender-1: #85f8eb;
$alchemist-status-blender-2: #4bd4c4;
$alchemist-status-blender-3: #00bda7;
$alchemist-status-success-stroke: #ebf1ff;

