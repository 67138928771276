// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
    font-family: 'Montserrat-Regular';
    src: url('../fonts/Montserrat/Montserrat-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('../fonts/Montserrat/Montserrat-Bold.ttf');
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: 'Montserrat-Thin';
    src: url('../fonts/Montserrat/Montserrat-Thin.ttf');
    font-weight: thin;
    font-style: thin;
}
